<template>
    <div class="coursePage">
        <div class="course_r">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:1,
        }
    },
    methods: {
        oBox(val){
            if(val==1){
                this.istrue = val
                this.$router.push({name:'Courseinformation'})
            }else if(val==2){
                this.istrue = val
                this.$router.push({name:'Personalcenter'})
            }
        },
        getPath(){
            if(this.$route.name=="Courseinformation"){
                this.istrue = 1
            }else if(this.$route.name=="Personalcenter"){
                this.istrue = 2
            }
        },
    },
    mounted() {
        this.getPath()
    },
    watch:{
        $route(to,from){
            this.getPath()
        }
    }
}
</script>
<style scoped>
@import './../../assets/css/teacher/coursePage.css';
</style>